.fs-14 {
  font-size: 14px !important;
}
.pt_40 {
  padding-top: 40px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.d_flex {
  display: flex !important;
}
.align_center {
  align-items: center;
}
.justify_center {
  justify-content: center;
}
.word_breaks{
  word-wrap: break-word!important;
  word-break: break-word!important;
}
.line_clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.line_clamp_ele_2 {
  -webkit-line-clamp: 2;
}
.line_clamp_ele_3 {
  -webkit-line-clamp: 3;
}
.login-image {
  background-image: radial-gradient(#e9f5f5, #e9f5f5);
  height: 100%;
}
.Polaris-TopBar-Menu__Activator {
  padding: 1.1rem 0.8rem !important;
}

.vendor-page {
  display: flex;
}

.vendor-page--side-nav {
  margin-right: 10px;
  transition: min-width 0.5s linear;
}

.vendor-page--main {
  flex: 0 0 80%;
  max-width: 80%;
}

.input_cursor .Polaris-TextField__Input {
  cursor: pointer;
}
.input_cursor input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.vendor-page--icon {
  position: absolute;
  left: 200px;
  padding: 10px 0;
}

.vendor-page--nav-wrap {
  position: relative;
  flex: 0 0 20%;
  max-width: 20%;
  min-height: 200px;
}

.hide.vendor-page--side-nav {
  width: 0;
}

.Polaris-Select__Input {
  cursor: pointer;
}

.dashboardpiechart,
.dashboardpiechart .Polaris-Card {
  height: 100% !important;
}
.dashboardpiechartcont .Polaris-Layout {
  align-items: unset !important;
}

@media only screen and (max-width: 767px) {
  .vendor-page {
    flex-wrap: wrap;
  }
  .vendor-page--side-nav {
    flex: 100%;
    width: 100%;
    margin-right: 0;
  }

  .vendor-page--main,
  .vendor-page--nav-wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .vendor-page--icon {
    display: none;
  }
}

.rsdt.rsdt-container{
  max-height:500px;
  border: 1px solid #dee2e6;
  border-radius: 7px;
 }
 .rsdt.rsdt-container .table th ,.rsdt.rsdt-container .table td {
   border-top: 0;
   vertical-align: middle;
   border-bottom:0;
 }
 /* .custom_scrollbar.custom_scrollbar_hoz::-webkit-scrollbar {
   height: 10px !important;
 } */
 .custom_scrollbar::-webkit-scrollbar {
   width: 10px;
   height: 10px;
 }
 .custom_scrollbar::-webkit-scrollbar-track {
   box-shadow: inset 0 0 2px #80808052;
   border-radius: 10px;
   background: #f1f1f1;
 }
 .custom_scrollbar::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 10px;
 }
 .custom_scrollbar::-webkit-scrollbar-thumb:hover {
   background: #a2a2a2d9;
 }

.notification_center .Polaris-ResourceItem__Container {
  align-items: center !important;
}
.merchant_pricingplan .react-multi-carousel-list.carousel-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.merchant_pricingplan .Polaris-Banner__Ribbon {
  display: none;
}
.merchant_pricingplan .react-multiple-carousel__arrow,
.merchant_pricingplan .react-multiple-carousel__arrow:hover {
  background: rgba(53, 128, 96, 0.9);
}
.merchant_pricingplan .react-multiple-carousel__arrow--left {
  left: 0;
}
.merchant_pricingplan .react-multiple-carousel__arrow--right {
  right: 5px;
}
.chartjsdashboard .Polaris-Layout {
  flex-wrap: nowrap;
}
.chartjsdashboard .Polaris-Layout__Section.Polaris-Layout__Section--oneHalf {
  flex: 0 0 50%;
  max-width: 50%;
}
.table-sm td, .table-sm th {
  padding: 10px !important;
}
.rsdt .smarttableheader{
    position: sticky;
    top: 0;
    background: #f7f7f7;
    z-index: 11;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.Polaris-TopBar__NavigationIcon{
  fill:#fff!important;
}
.Custom_PolarisSideNav{
  font-weight: var(--p-font-weight-semibold);
 padding:var(--p-space-2);
 width:100%;
}
.Custom_PolarisSelected{
  background: var(--p-surface-selected);
    outline: var(--p-border-width-1) solid transparent;
    position: relative;
}
.Custom_PolarisSelected::before {
  content: '';
  background-color: var(--p-interactive);
  position: absolute;
  top: 0;
  left: calc(var(--p-space-2)*-1);
  height: 100%;
  display: block;
  width: var(--p-border-width-3);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}
.Custom_PolarisSideNav:hover {
  background: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar-Menu__Activator:focus:after {
  box-shadow: none !important;
  outline: none !important;
}
.Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded='true'] {
  background-color: transparent !important;
  outline: none !important;
  transition: none !important;
}
.pt_10 {
  padding-top: 10px;
}
.pb_10 {
  padding-bottom: 10px;
}
.mt_0{
  margin-top: 0px;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.loader_spin .Polaris-Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}